
import login from '~/mixins/common/pages/login'
import checkSuspendWebsite from '~/mixins/pages/login/checkSuspendWebsite'
export default {
    name: 'Login',
    mixins: [login, checkSuspendWebsite],
    middleware: 'auth',
    data: () => ({}),
    computed: {
        loading() {
            return this.$store.state.player.loading
        },
    },
}
